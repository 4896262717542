// App.js
import React from "react";
import "./App.css";
import Header from "./list/Header";
import EventCategory from "./list/EventCategory";
import Images from "./list/images"; // Возможно, нужно изменить на правильный компонент
import DateSelector from "./list/DateSelector";
import NavigationMenu from "./list/navigation";



function App() {
  return (
    <div className="app">
      <Header />
      <NavigationMenu/>
      <header>
       
        <DateSelector />
      </header>
     
      <main className="main-container">
        
        <Images title="♥" />
        
        <EventCategory className='title_area' title="Рекомендации" />
        <EventCategory className='title_area' title="Концерты" />
        <EventCategory className='title_area' title="Спорт" />
        <EventCategory className='title_area' title="Кино" />
        <EventCategory className='title_area' title="Новое" />
      </main>
      <footer>
      </footer>
    </div>
  );
}

export default App;
