// components/EventCategory.styles.js
import styled from 'styled-components';

export const EventCategoryContainer = styled.section`
  margin-top: 60px;
`;

export const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 15px;
  text-align: left;
`;

export const ScrollButton = styled.button`
  position: absolute;
  background-color: #fff;
  border: none;
  font-size: 36px;
  cursor: pointer;
  z-index: 1;

  &.left {
    left: -40px; // Положение кнопки влево
  }

  &.right {
    right: -40px; // Положение кнопки вправо
  }

  &:hover {
    color: #f2c594; // Изменение цвета при наведении
  }
`;

export const EventList = styled.div`
  display: flex;
  gap: 30px; // Отступ между карточками
  overflow-x: hidden; // Возможность прокрутки
  scroll-behavior: smooth; // Плавная прокрутка
  padding: 15px 0;
  max-width: 100%; // Ограничиваем ширину списка

  &::-webkit-scrollbar {
    display: none; // Скрыть полосу прокрутки
  }

  @media (max-width: 768px) {
    gap: 15px; // Меньший отступ между карточками на мобильных устройствах
  }

  @media (min-width: 1200px) {
    gap: 40px; // Больше отступ для больших экранов
  }
`;
