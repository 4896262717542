// components/EventCategory.js
import React, { useRef } from "react";
import EventCard from "./EventCard";
import {
  EventCategoryContainer,
  Title,
  ScrollButton,
  EventList,
} from "./EventCategory.styles";

const EventCategory = ({ title }) => {
  const scrollRef = useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === "left") {
      current.scrollLeft -= 300; // Увеличиваем прокрутку для десктопа
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <EventCategoryContainer>
      <Title>{title}</Title>
      <div className="event-category__container">
        <ScrollButton className="left" onClick={() => scroll("left")}>
          &#8592;
        </ScrollButton>
        <EventList ref={scrollRef}>
          <EventCard name="Йога" imgSrc="link_to_image_1" />
          <EventCard name="Halloween Party" imgSrc="link_to_image_2" />
          <EventCard name="Концерт" imgSrc="link_to_image_3" />
          <EventCard name="Stand-Up" imgSrc="link_to_image_4" />
          <EventCard name="Футбол" imgSrc="link_to_image_5" />
          <EventCard name="Танцы" imgSrc="link_to_image_6" />
          <EventCard name="Кулинарный мастер-класс" imgSrc="link_to_image_7" />
          <EventCard name="Киноночь" imgSrc="link_to_image_8" />
          <EventCard name="Выставка" imgSrc="link_to_image_9" />
          <EventCard name="Марафон" imgSrc="link_to_image_10" />
          <EventCard name="Семинар" imgSrc="link_to_image_11" />
          <EventCard name="Театр" imgSrc="link_to_image_12" />
          <EventCard name="Концерт классической музыки" imgSrc="link_to_image_13" />
        </EventList>
        <ScrollButton className="right" onClick={() => scroll("right")}>
          &#8594;
        </ScrollButton>
      </div>
    </EventCategoryContainer>
  );
};

export default EventCategory;
