import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

// Стили для DateSelector
const DateSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрируем содержимое по горизонтали */
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const MonthLabel = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center; /* Центрируем текст месяца */
`;

const DateListContainer = styled.div`
  display: flex;
  align-items: center; /* Центрируем содержимое вертикально */
  justify-content: center;
  width: 100%;
`;

const DateList = styled.div`
  display: flex;
  justify-content: center; /* Центрируем блок с датами */
  width: auto;
  overflow: hidden; /* Скрываем прокрутку */
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5px; /* Добавляем отступы между днями */
`;

const DateCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &.today {
    background-color: #ffeb3b; /* Цвет для сегодняшней даты */
  }

  &.selected {
    background-color: #4caf50; /* Цвет для выбранной даты */
    color: #fff;
  }
`;

const DateNumber = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;

const DayOfWeek = styled.div`
  font-size: 0.8rem;
  margin-top: 5px;
`;

const ScrollButton = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  transition: color 0.3s;

  &:hover {
    color: #333;
  }
`;

// Основной компонент
const DateSelector = () => {
  const [currentDate] = useState(new Date()); // Текущая дата
  const [selectedDate, setSelectedDate] = useState(null);
  const [offset, setOffset] = useState(0); // Смещение для перемотки
  const [userScrolled, setUserScrolled] = useState(false); // Флаг перемотки пользователем

  // Генерация дат на несколько месяцев вперед
  const generateFutureDates = (monthsToGenerate) => {
    const dates = [];
    let current = new Date(currentDate);

    for (let i = 0; i < monthsToGenerate; i++) {
      const daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(current.getFullYear(), current.getMonth(), day);
        dates.push(date);
      }
      current.setMonth(current.getMonth() + 1); // Переход на следующий месяц
    }
    return dates;
  };

  const futureDates = generateFutureDates(12); // Сгенерировать даты на год вперёд

  // Инициализация смещения на сегодняшнюю дату при первом рендере
  useEffect(() => {
    if (!userScrolled) {
      const todayIndex = futureDates.findIndex(
        (date) => date.toDateString() === currentDate.toDateString()
      );
      setOffset(todayIndex); // Устанавливаем смещение на сегодняшнюю дату
    }
  }, [currentDate, futureDates, userScrolled]);

  // Функция для клика на день
  const handleDayClick = (date) => {
    setSelectedDate(date);
    performSearch(date); // Поиск по выбранной дате
  };

  // Функция для поиска по выбранной дате
  const performSearch = (date) => {
    const formattedDate = date.toISOString().split('T')[0]; // Форматируем дату в YYYY-MM-DD
    console.log(`Ищем мероприятия на дату: ${formattedDate}`);
    // Ваш код для выполнения поиска по дате
  };

  // Функция для перемотки вперед и назад
  const scrollDates = (direction) => {
    const maxOffset = futureDates.length - 15; // Отнимаем количество видимых элементов (15)
    setUserScrolled(true); // Устанавливаем флаг, что пользователь прокручивает даты
    if (direction === 'left' && offset > 0) {
      setOffset(offset - 15); // Перемотка по 15 дней назад
    } else if (direction === 'right' && offset < maxOffset) {
      setOffset(offset + 15); // Перемотка по 15 дней вперед
    }
  };

  // Функция для получения месяца по текущему смещению
  const getCurrentMonth = () => {
    const currentMonthDate = futureDates[offset];
    return currentMonthDate.toLocaleString('default', { month: 'long' });
  };

  return (
    <DateSelectorWrapper>
      {/* Отображение текущего месяца */}
      <MonthLabel>
        {getCurrentMonth()}
      </MonthLabel>

      {/* Блок с датами и кнопками */}
      <DateListContainer>
        <ScrollButton className="left" onClick={() => scrollDates('left')}>❮</ScrollButton>

        <DateList>
          {futureDates.slice(offset, offset + 15).map((date, index) => (
            <DateWrapper key={index}>
              {/* Кружок с числом и днем недели */}
              <DateCircle
                className={`${selectedDate && selectedDate.toDateString() === date.toDateString() ? 'selected' : ''} 
                  ${date.toDateString() === currentDate.toDateString() ? 'today' : ''}`}
                onClick={() => handleDayClick(date)}
              >
                <DateNumber>{date.getDate()}</DateNumber>
                <DayOfWeek>{date.toLocaleString('default', { weekday: 'short' })}</DayOfWeek>
              </DateCircle>
            </DateWrapper>
          ))}
        </DateList>

        <ScrollButton className="right" onClick={() => scrollDates('right')}>❯</ScrollButton>
      </DateListContainer>
    </DateSelectorWrapper>
  );
};

export default DateSelector;
