// components/Header.js
import React, { useState } from 'react';
import styled from 'styled-components';

// Стили для Header
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const LogoVLru = styled.span`
  font-size: 2rem;
  font-weight: bold;
  color: #f57c00; /* Оранжевый цвет для VL.RU */
  padding-right: 10px;
`;

const LogoAfisha = styled.span`
  font-size: 1.5rem;
  margin-left: 5px;
  padding-right: 20px;
`;

const CitySelector = styled.div`
  position: relative; /* Сохраняем относительное позиционирование для выпадающего списка */
`;

const LogoLocation = styled.span`
  font-size: 1rem;
  margin-left: 10px;
  color: #666;
  border-bottom: 1px dashed;
  cursor: pointer;
  user-select: none;
`;

const CityDropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const CityItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const SearchForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-width: 250px; /* Устанавливаем минимальную ширину */
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  font-size: 1rem;
`;

const SearchButton = styled.button`
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-left: none;
  border-radius: 0 5px 5px 0;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 10px;
`;

const AddEventButton = styled(Button)`
  background-color: #4285f4;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #357ae8;
  }
`;

const FavoritesButton = styled(Button)`
  font-size: 1.5rem;
`;

const Header = () => {
  const [isCityDropdownOpen, setIsCityDropdownOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState('Владивосток');
  const cities = ['Владивосток', 'Уссурийск', 'Находка', 'Артём'];

  const handleSearch = (event) => {
    event.preventDefault();
    const query = event.target.elements.search.value;
    console.log(`Поиск по запросу: ${query}`);
    // Логика для поиска по сайту
  };

  const openNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <HeaderContainer>
      {/* Логотип */}
      <Logo>
        <LogoVLru>VL.RU</LogoVLru>
        <LogoAfisha>Афиша</LogoAfisha>

        {/* Кнопка для выбора города */}
        <CitySelector>
          <LogoLocation onClick={() => setIsCityDropdownOpen(!isCityDropdownOpen)}>
            {selectedCity} ▼
          </LogoLocation>

          {/* Выпадающий список городов */}
          {isCityDropdownOpen && (
            <CityDropdown>
              {cities.map((city, index) => (
                <CityItem
                  key={index}
                  onClick={() => {
                    setSelectedCity(city);
                    setIsCityDropdownOpen(false);
                    console.log(`Выбран город: ${city}`);
                  }}
                >
                  {city}
                </CityItem>
              ))}
            </CityDropdown>
          )}
        </CitySelector>
      </Logo>

      {/* Поле поиска */}
      <SearchForm onSubmit={handleSearch}>
        <SearchInput
          type="text"
          name="search"
          placeholder="Поиск"
        />
        <SearchButton type="submit">🔍</SearchButton>
      </SearchForm>

      {/* Кнопки справа */}
      <HeaderButtons>
        <AddEventButton onClick={() => openNewTab('https://example.com/add-event')}>
          Добавить событие
        </AddEventButton>
        <Button onClick={() => openNewTab('https://example.com/login')}>
          Войти
        </Button>
        <FavoritesButton onClick={() => openNewTab('https://example.com/favorites')}>
          ❤️
        </FavoritesButton>
      </HeaderButtons>
    </HeaderContainer>
  );
};

export default Header;
