// components/imgCard.js
import React from "react";
import styled from "styled-components";

// Стили для imgCard
const ImgCardContainer = styled.div`
  background-image: url('./vl_ru.jpg');
  background-size: cover; /* Лучше использовать cover для сохранения пропорций */
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 1200px; 
  height: 300px; /* Высота карточек */
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.05);
  }
`;

const Img = styled.img`
  width: auto; /* Позволить изображению сохранять свои пропорции */
  border-radius: 10px 10px 0 0;
`;

const ImgInfo = styled.div`
  width: 300px;
  padding: 15px;
`;

const ImgName = styled.h3`
  font-size: 18px;
  margin-bottom: 8px;
  height: 50px;
  width: 300px;
`;

const ImgDate = styled.p`
  font-size: 14px;
  color: #888;
`;

const Images = ({ name, imgSrc }) => {
  return (
    <ImgCardContainer>
      <div className="vl.ru">
        <Img src={imgSrc} alt={name} />
      </div>
      <ImgInfo>
        <ImgName>{name}</ImgName>
        <ImgDate></ImgDate> {/* Если дата не нужна, можно удалить эту строку */}
      </ImgInfo>
    </ImgCardContainer>
  );
};

export default Images;
