// components/NavigationMenu.js
import React, { useState } from 'react';
import styled from 'styled-components';

// Стили для навигационного меню
const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const NavItem = styled.li`
  margin: 0;
  position: relative;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #f5f5f5;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f2c594;
  }
`;

const DropdownList = styled.ul`
  list-style-type: none;
  padding: 10px;
  margin-top: 5px;
  background-color: #f8f9fa;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  background-color: #F5F5F5;
`;

const DropdownItem = styled.li`
  margin: 5px 0;

  ${Button} {
    background-color: #F5F5F5;

    &:hover {
      background-color: #f2c594;
    }
  }
`;

const NavigationMenu = () => {
  const [showMore, setShowMore] = useState(false);

  const handleToggleMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Nav>
      <NavList>
        <NavItem><Button>Концерты</Button></NavItem>
        <NavItem><Button>Театры</Button></NavItem>
        <NavItem><Button>Детям</Button></NavItem>
        <NavItem><Button>Стендап</Button></NavItem>
        <NavItem><Button>Спорт</Button></NavItem>
        <NavItem><Button>Городское событие</Button></NavItem>
        <NavItem><Button>Музеи и галереи</Button></NavItem>

        {/* Кнопка "Ещё" с выпадающим меню */}
        <NavItem>
          <Button onClick={handleToggleMore}>
            Ещё {showMore ? '▲' : '▼'}
          </Button>

          {showMore && (
            <DropdownList>
              <DropdownItem><Button>Кино</Button></DropdownItem>
              <DropdownItem><Button>Экскурсии и туры</Button></DropdownItem>
              <DropdownItem><Button>Вечеринки</Button></DropdownItem>
              <DropdownItem><Button>Впечатления</Button></DropdownItem>
              <DropdownItem><Button>Мастер-классы</Button></DropdownItem>
              <DropdownItem><Button>Обучение</Button></DropdownItem>
            </DropdownList>
          )}
        </NavItem>
      </NavList>
    </Nav>
  );
};

export default NavigationMenu;
