// components/EventCard.js
import React from "react";
import styled from "styled-components";

// Стили для EventCard
const EventArea = styled.div`
  width: 1200px;
  height: 300px;
  display: flex; /* Используем flex для выравнивания элементов внутри */
  justify-content: center; /* Центрируем элементы по горизонтали */
  align-items: center; /* Центрируем элементы по вертикали */
`;

const Card = styled.div`
  background-image: url('./vl_ru.jpg');
  background-size: cover; /* Лучше использовать cover для сохранения пропорций */
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 300px; /* Высота карточек */
  height: 400px; 
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column; /* Выравниваем элементы по вертикали */
  justify-content: space-between; /* Равномерное распределение пространства между элементами */
  position: relative; /* Необходим для псевдоэлементов */

  &:hover {
    transform: scale(1.05);
  }
`;

const CardImage = styled.div`
  img {
    width: 100%; /* Задать 100%, чтобы изображение занимало всю ширину карточки */
    border-radius: 10px 10px 0 0; /* Радиус верхних углов */
  }
`;

const CardInfo = styled.div`
  padding: 15px;
  width: 100%; /* Полная ширина контейнера */
  display: flex;
  flex-direction: column; /* Вертикальное расположение текста */
  align-items: center; /* Центрируем текст по горизонтали */
`;

const CardName = styled.h3`
  font-size: 18px;
  margin-bottom: 8px;
  height: 50px;
  width: 100%; /* Полная ширина для названия */
  text-align: center; /* Центрируем текст по горизонтали */
`;

const CardDate = styled.p`
  font-size: 14px;
  color: #888;
`;

// Основной компонент
const EventCard = ({ name, imgSrc }) => {
  return (
    <EventArea>
      <Card>
        <CardImage>
          <img src={imgSrc} alt={name} />
        </CardImage>
        <CardInfo>
          <CardDate className="event-card__date"></CardDate>
          <CardName className="event-card__name">{name}</CardName>
        </CardInfo>
      </Card>
    </EventArea>
  );
};

export default EventCard;
